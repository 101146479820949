import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LocalStorageManager } from '../../util/localStorageManager'
import StoreInputComponent from '../../component/StoreInputComponent'
import StoreHeader from '../../component/StoreHeader'

const StoreRegisterFirst = () => {
  const navigate = useNavigate()
  const [store, setStore] = useState({
    storeName: '',
    storeNumber: '',
    storeAddress: '',
    storeAccess: '',
    storeOpening: '',
    storeLast: '',
    storeClosed: '',
    policy: '',
  })

  useEffect(() => {
    const exist = LocalStorageManager.existItem(LocalStorageManager.store)
    if (exist) {
      alert('すでに店舗を登録済みです。')
      navigate('/home')
      return
    }
    const storeString = localStorage.getItem(LocalStorageManager.tempStore)
    if (storeString) {
      const storeData = JSON.parse(storeString)
      setStore({
        ...store,
        storeName: storeData.storeName,
        storeNumber: storeData.storeNumber,
        storeAddress: storeData.storeAddress,
        storeAccess: storeData.storeAccess,
        storeOpening: storeData.storeOpening,
        storeLast: storeData.storeLast,
        storeClosed: storeData.storeClosed,
        policy: storeData.policy,
      })
    }
    // eslint-disable-next-line
  }, [])

  const stepNext = () => {
    if (!Object.values(store).every((value) => value)) {
      return
    }
    const prevStoreDataString = localStorage.getItem(LocalStorageManager.tempStore)
    if (prevStoreDataString) {
      const prevStoreData = JSON.parse(prevStoreDataString)
      LocalStorageManager.setObjectItem(LocalStorageManager.tempStore, { ...prevStoreData, ...store })
    } else {
      LocalStorageManager.setObjectItem(LocalStorageManager.tempStore, store)
    }
    navigate('/store-register-second')
  }

  return (
    <div className='default-container bg-default m-auto'>
      <StoreHeader title={'お店について教えてください'} page={1} />
      <div className='grid-default-header overflow-y'>
        <div className='color-gray-accent ms-3 mb-3 text-medium'>後で変更できます</div>
        <StoreInputComponent store={store} setStore={setStore} handleChange={stepNext} />
        <div className='py-2'></div>
      </div>
    </div>
  )
}

export default StoreRegisterFirst
