import React from 'react'
import { useNavigate } from 'react-router-dom';

const BookingHeader = ({ pageTitle, value, setPreview, preview }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (preview) {
            setPreview(false)
            return;
        }
        navigate(value)
    }

    return (
        <div className="top-green" style={{ height: preview ? '100px' : '160px' }}>
            <div className='text-white text20'><i className="ms-1 fa fa-arrow-left cursor-pointer" onClick={() => handleClick()}></i></div>
            <div className='text20 text-white text-center'>{pageTitle}</div>
            <div style={{ width: '20px' }}></div>
        </div>
    )
}

export default BookingHeader
