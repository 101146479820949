import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TopHeader from '../../component/TopHeader'
import axios from 'axios'
import { decrypt } from '../../util/crypto'
import DetailComponent from '../../component/DetailComponent'

const SendingBookDetail = () => {
  const param = useParams()
  const [detail, setDetail] = useState(null)

  useEffect(() => {
    if (!param.id) {
      return
    }
    axios
      .get(`/api/history/${decrypt(param.id)}`)
      .then((res) => {
        if (res.data && res.data.detail) {
          setDetail(res.data.detail)
        }
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
  }, [param])

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'予約内容'} value={-1} />
      <div className='grid-default-resize pt-2'>
        <DetailComponent detail={detail} />
      </div>
    </div>
  )
}

export default SendingBookDetail
