import { logout } from "./logout";

export class LocalStorageManager {
    static store = 'store'
    static user = 'user'
    static token = 'token'
    static tempUser = 'tempUser'
    static tempStore = 'tempStore'

    static existItem(key) {
        return localStorage.getItem(key) ? true : false;
    }

    static getObjectItem(key) {
        const valueString = localStorage.getItem(key);
        if (valueString) {
            return JSON.parse(valueString);
        } else {
            logout();
        }
    }

    static getItem(key) {
        const value = localStorage.getItem(key);
        if (value) {
            return value;
        } else {
            logout();
        }
    }

    static setObjectItem(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static setItem(key, value) {
        localStorage.setItem(key, value);
    }

    static removeItem(key) {
        localStorage.removeItem(key);
    }
}