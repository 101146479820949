import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TopHeader from '../../component/TopHeader'
import { Modal, ModalBody } from 'reactstrap'
import { logout } from '../../util/logout'
import { LocalStorageManager } from '../../util/localStorageManager'
import CustomButton from '../../component/CustomButton'
import CustomItem from '../../component/CustomItem'

const Setting = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggle = () => setIsModalOpen(!isModalOpen)

  const stepNext = (url) => {
    if (url === '/card-check') {
      const store = LocalStorageManager.getObjectItem(LocalStorageManager.store)
      if (!store.tenantId) {
        alert('振り込み先を登録してください。')
        return
      }
    }
    navigate(url)
  }

  const handleAccountDelete = () => {
    navigate('/delete-account')
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'設定'} value={'/my-page'} />
      <div className='grid-default-resize'>
        <CustomItem text={'登録情報変更'} icon={'fa fa-user'} handleClick={() => stepNext('/registrant-info')} />
        <CustomItem text={'プランの確認'} icon={'fa fa-yen'} handleClick={() => stepNext('/plan-check')} />
        <CustomItem text={'決済方法の設定'} icon={'fa fa-list-check'} handleClick={() => stepNext('/card-check')} />
        <CustomItem text={'利用規約'} icon={'fa-regular fa-file-lines'} handleClick={() => (window.location.href = 'https://billowy-eggnog-f9d.notion.site/733076d011ba4963bf16f483972fabf4')} />
        <CustomItem
          text={'プライバシーポリシー'}
          icon={'fa-regular fa-file-lines'}
          handleClick={() => (window.location.href = 'https://billowy-eggnog-f9d.notion.site/78c3bf7d23ac4a028029066bfd8b7f8b')}
        />
        <CustomItem
          text={'特定商取引法に基づく表示'}
          icon={'fa-regular fa-file-lines'}
          handleClick={() => (window.location.href = 'https://billowy-eggnog-f9d.notion.site/104f3769ab2c803da491df44d8977a0d')}
        />
        <CustomItem text={'ログアウト'} icon={'fa-solid fa-arrow-right-from-bracket'} handleClick={toggle} />
        <div className='text-center mt-4 pt-2'>
          <div className='color-red  cursor-pointer' onClick={() => handleAccountDelete()}>
            <span className='text12'>
              <i className='fa fa-trash me-2'></i>
            </span>
            <span className='text-medium'>アカウント削除</span>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} toggle={toggle} className='cancel-modal logout-modal' centered size='sm'>
        <ModalBody>
          <div className='d-flex flex-column align-items-center pt-3'>
            <div className='text-center color-gray-main text20 mb-1'>ログアウトします。</div>
            <div className='text-center color-gray-main text20 mb-4 pb-3'>よろしいですか？</div>
            <CustomButton text={'ログアウトする'} handleOnClick={() => logout()} />
            <div className='text-center color-gray  mt-4'>
              <span className='cursor-pointer text-medium text16' onClick={toggle}>
                キャンセル
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Setting
