import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import TopHeader from '../../component/TopHeader'
import { decrypt } from '../../util/crypto'
import CustomButton from '../../component/CustomButton'
import InputPassword from '../../component/inputs/InputPassword'

const Password = () => {
  const navigate = useNavigate()
  const param = useParams()
  const [isShow, setIsShow] = useState(false)
  const [password, setPassword] = useState(null)
  const [token, setToken] = useState(null)
  const [isSuccess, setIsSuccess] = useState(false)

  useEffect(() => {
    if (param.id) {
      axios
        .get('/api/getChangePasswordToken/' + decrypt(param.id))
        .then((res) => {
          setToken(res.data.token)
          console.log(res)
        })
        .catch((error) => {
          alert(error.response?.data?.message ?? 'エラー')
        })
    } else {
      navigate('/login')
    }
  }, [navigate, param])

  const handleSubmit = () => {
    if (password === '') {
      return
    }
    axios
      .post('/api/changePassword', { token: token, password: password })
      .then(() => {
        setIsSuccess(true)
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
  }

  const handlePasswordShow = () => {
    if (password) {
      setIsShow(!isShow)
    }
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'新しいパスワードの設定'} isHide={isSuccess ? false : true} value={'/login'} />
      <div className='grid-default'>
        {!isSuccess ? (
          <>
            <InputPassword isShow={isShow} handlePasswordShow={handlePasswordShow} password={password} handleChange={(e) => setPassword(e.target.value)} />
            <div className='mb-5'></div>
            <CustomButton text={'パスワードを設定する'} handleOnClick={() => handleSubmit()} disabled={password ? false : true} />
          </>
        ) : (
          <div>
            <div className='color-gray-accent mt-2 mb-5 text16 text-center'>
              パスワードの再設定が<br></br>完了しました
            </div>
            <div className='mt-4'></div>
            <CustomButton text={'ログインへ戻る'} handleOnClick={() => navigate('/login')} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Password
