import React from 'react'
import { useNavigate } from 'react-router-dom'
import bgComplete from '../../assets/complete.svg'
import { LocalStorageManager } from '../../util/localStorageManager'
import CustomButton from '../../component/CustomButton'
import StoreHeader from '../../component/StoreHeader'

const StoreRegisterLast = () => {
  const navigate = useNavigate()

  const stepNext = () => {
    LocalStorageManager.removeItem(LocalStorageManager.tempStore)
    navigate('/home')
  }

  return (
    <div className='default-container bg-default m-auto'>
      <StoreHeader title={'登録完了'} page={4} />
      <div className='grid-default-header pt-0'>
        <div className='color-gray-accent text20 text-center text-medium'>入力お疲れ様でした！</div>
        <div className='color-gray-accent text16 text-center text-medium mt-2'>CONECTICを始めましょう！</div>
        <div className='text-center my-4'>
          <img src={bgComplete} width='70%' alt='SMS Background' />
        </div>
        <div className='mt-4'></div>
        <CustomButton text={'はじめる'} handleOnClick={() => stepNext()} />
      </div>
    </div>
  )
}

export default StoreRegisterLast
