import React from 'react'
import GreenHeader from '../../component/GreenHeader'
import { POLICY_TEXT } from '../../constant/constant'
import GoogleMapsComponent from '../../component/GoogleMapsComponent'

const Contact = ({ store, isContact }) => {
  return (
    <div className='default-container bg-default m-auto'>
      {store && (
        <>
          <GreenHeader pageTitle={'店舗情報'} name={store.storeName} isContact={isContact} />
          {store.deleted_dt !== null && <div className='deleted-bar text-white '>この店舗は現在conecticからの受付を行なっておりません。</div>}
          <div className='grid-default-resize pt-2 pb-5'>
            <div className='input-box mt-3'>
              <div className='color-gray-main mb-1'>店舗電話番号</div>
              <div className='d-flex justify-content-between'>
                <div className='roboto text18'>{store.storeNumber}</div>
                <a href={`tel:${store.storeNumber}`} className='no-underline'>
                  <div className='color-gray-sub'>電話をかける</div>
                </a>
              </div>
            </div>
            <div className='input-box-map mt-3'>
              <div className='p-3 pb-2'>
                <div className='color-gray-main mb-1'>住所</div>
                <div className='color-gray-font text16 pre-wrap'>{store.storeAddress}</div>
                <hr className='color-gray-main'></hr>
                <div className='color-gray-font text16 pre-wrap'>{store.storeAccess}</div>
              </div>
              <div className='w-100 h250 p-1' id='map'>
                <GoogleMapsComponent address={store.storeAddress} />
              </div>
            </div>
            <div className='input-box mt-3'>
              <div className='color-gray-main mb-1'>営業時間</div>
              <div className='color-gray-font text16 pre-wrap'>{store.storeOpening}</div>
              <hr className='color-gray-main'></hr>
              <div className='color-gray-main mb-1'>最終受付時間</div>
              <div className='color-gray-font text16 pre-wrap'>{store.storeLast}</div>
              <hr className='color-gray-main'></hr>
              <div className='color-gray-main mb-1'>定休日</div>
              <div className='color-gray-font text16 pre-wrap'>{store.storeClosed}</div>
            </div>
            <div className='input-box mt-3'>
              <div className='color-gray-main mb-1'>キャンセルポリシー</div>
              <div className='color-gray-font text16 pre-wrap'>{POLICY_TEXT[store.policy - 1]}</div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Contact
