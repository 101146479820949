import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TopHeader from '../../component/TopHeader'
import bgCall2 from '../../assets/call-2.svg'
import CustomButton from '../../component/CustomButton'

const AcceptBookingComplete = () => {
  const navigate = useNavigate()
  const param = useParams()

  const stepNext = () => {
    navigate('/home')
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'送信完了'} value={'/home'} isGo={true} />
      <div className='grid-default pt-0 grid-half'>
        <div className='text-center my-auto'>
          <img src={bgCall2} alt='SMS'></img>
        </div>
        <div className='mt-5'>
          <div className='color-gray-main text20 text-center text-medium'>お客様に予約内容を送信しました！</div>
          <div className='mt-5'></div>
          <CustomButton text='TOPへ戻る' handleOnClick={() => stepNext()} />
          <div className='color-gray-main text-center mt-3 cursor-pointer' onClick={() => navigate('/history-detail/' + param.id)}>
            送信済みの予約詳細へ
          </div>
        </div>
      </div>
    </div>
  )
}

export default AcceptBookingComplete
