import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TopHeader from '../../component/TopHeader'
import axios from 'axios'
import { LocalStorageManager } from '../../util/localStorageManager'
import CustomButton from '../../component/CustomButton'
import errorInfo from '../../assets/error_outline.png'

const DeleteAccount = () => {
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [checkedStatus, setCheckedStatus] = useState({
    first: false,
    second: false,
    third: false,
  })

  const handleAccountDelete = () => {
    if (isSubmitting) return
    setIsSubmitting(true)

    const user = LocalStorageManager.getObjectItem(LocalStorageManager.user)

    axios
      .delete('/api/userDelete/' + user._id)
      .then((res) => {
        alert('アカウントが削除されました。')
        navigate('/login')
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'アカウントを削除'} value={'/setting'} />
      <div className='grid-default-resize pt-2'>
        <div className='text-medium color-gray-font'>下記の項目をご確認のうえ、チェックを入れてください。</div>
        <div className='mt-3 cursor-pointer' onClick={() => setCheckedStatus({ ...checkedStatus, first: !checkedStatus.first })}>
          <div className='d-flex'>
            <div className={`text20 ${checkedStatus.first ? 'check-indicator' : 'disabled'}`}>
              <i className='fa fa-circle-check me-3'></i>
            </div>
            <div>
              <div className='color-gray-font text16 mb-1'>アカウント情報について</div>
              <div className='color-gray-main text-medium'>アカウント削除後は、登録内容と予約内容の閲覧ができなくなります。</div>
            </div>
          </div>
        </div>
        <div className='mt-4 cursor-pointer' onClick={() => setCheckedStatus({ ...checkedStatus, second: !checkedStatus.second })}>
          <div className='d-flex'>
            <div className={`text20 ${checkedStatus.second ? 'check-indicator' : 'disabled'}`}>
              <i className='fa fa-circle-check me-3'></i>
            </div>
            <div>
              <div className='color-gray-font text16 mb-1'>未処理の予約について</div>
              <div className='color-gray-main text-medium'>事前決済されたすべての予約の与信枠は解約されます。</div>
            </div>
          </div>
        </div>
        <div className='mt-4 cursor-pointer' onClick={() => setCheckedStatus({ ...checkedStatus, third: !checkedStatus.third })}>
          <div className='d-flex'>
            <div className={`text20 ${checkedStatus.third ? 'check-indicator' : 'disabled'}`}>
              <i className='fa fa-circle-check me-3'></i>
            </div>
            <div>
              <div className='color-gray-font text16 mb-1'>未処理の予約について</div>
              <div className='color-gray-main text-medium'>アカウントの削除する前にプランのキャンセルを行なってください。</div>
              <div className='color-gray-main text-medium'>
                キャンセルは
                <span onClick={() => navigate('/plan-check')} style={{ color: '#0B78DC' }}>
                  こちら
                </span>
                から行ってください。
              </div>
            </div>
          </div>
        </div>
        <div className='color-gray-font text-center text-medium mt-5 mb-3'>
          <img src={errorInfo} alt='error' />
          <span>アカウントの復元はできません。</span>
        </div>
        <CustomButton text={'アカウントを削除する'} color={'error'} handleOnClick={() => handleAccountDelete()} disabled={!(checkedStatus.first && checkedStatus.second && checkedStatus.third)} />
        <div className='p-3'></div>
      </div>
    </div>
  )
}

export default DeleteAccount
