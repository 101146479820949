import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { LocalStorageManager } from '../../util/localStorageManager'
import TopHeader from '../../component/TopHeader'
import Visa from '../../assets/brands/icon_visa.png'
import MasterCard from '../../assets/brands/icon_mastercard.png'
import JCB from '../../assets/brands/icon_jcb.png'
import Discover from '../../assets/brands/icon_discover.png'
import AmericanExpress from '../../assets/brands/icon_americanexpress.png'
import DinersClub from '../../assets/brands/icon_dinersclub.png'
import { Spinner } from 'reactstrap'
import CustomButton from '../../component/CustomButton'

const PlanCard = () => {
  const [currentUser, setCurrentUser] = useState(null)
  const [currentCard, setCurrentCard] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const getImage = (name) => {
    switch (name) {
      case 'Visa':
        return Visa
      case 'MasterCard':
        return MasterCard
      case 'JCB':
        return JCB
      case 'Discover':
        return Discover
      case 'American Express':
        return AmericanExpress
      case 'DinersClub':
        return DinersClub
      default:
        break
    }
  }

  const getHeight = (name) => {
    switch (name) {
      case 'Visa':
        return '12px'
      case 'MasterCard':
        return '28px'
      case 'JCB':
        return '26px'
      case 'Discover':
        return '20px'
      case 'American Express':
        return '20px'
      case 'DinersClub':
        return '22px'
      default:
        break
    }
  }

  useEffect(() => {
    const user = LocalStorageManager.getObjectItem(LocalStorageManager.user)
    setCurrentUser(user)

    const script = document.createElement('script')
    script.src = 'https://checkout.pay.jp/'
    script.className = 'payjp-button'
    script.setAttribute('data-key', process.env.REACT_APP_PAYJP_PUBLICKEY)
    script.setAttribute('data-submit-text', 'トークンを作成する')
    script.setAttribute('data-partial', 'true')
    script.async = true
    document.querySelector('#checkout').appendChild(script)

    axios
      .post('/api/getPlanCard', { userId: user?._id })
      .then((res) => {
        setCurrentCard(res.data.card)
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
      })
    return () => {
      window.PayjpCheckout = null
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = () => {
    if (isSubmitting) return
    document.querySelector('input[name="payjp-token"]').value = null
    const button = document.querySelector('#payjp_checkout_box').children[0]
    button.click()
    const handleInputChange = (mutations) => {
      if (mutations[0].target.value) {
        setIsSubmitting(true)
        axios
          .post('/api/changePlanCard', { token: mutations[0].target.value, userId: currentUser?._id })
          .then(() => {
            window.PayjpCheckout = null
            window.location.reload()
          })
          .catch((error) => {
            alert(error.response?.data?.message ?? 'エラー')
          })
          .finally(() => {
            setIsSubmitting(false)
          })
      }
    }

    const observer = new MutationObserver(handleInputChange)
    observer.observe(document.querySelector('input[name="payjp-token"]'), {
      attributes: true,
      attributeFilter: ['value'],
    })
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'カード情報の確認'} isGo={true} value={'/plan-check'} />
      <div className='grid-default-header pt-0 pb-5'>
        <div id='checkout' className='d-none'></div>
        {isSubmitting ? (
          <div className='mt-5 color-gray-main text-center'>
            <Spinner />
          </div>
        ) : (
          <>
            {currentCard && (
              <>
                <div className='mt-3 input-box'>
                  <div className='d-flex align-items-center'>
                    <img src={getImage(currentCard.brand)} className='me-2' alt='brand' style={{ height: getHeight(currentCard.brand) }} />
                    <div className='color-gray-font text18 roboto ps-2'>**** **** **** {currentCard.last4}</div>
                  </div>
                  <div className='mt-2'>
                    <span className='color-gray-main text-medium'>有効期限 : </span>
                    <span className='roboto color-gray-font ms-2'>
                      {currentCard.exp_month} / {currentCard.exp_year}
                    </span>
                  </div>
                </div>
                <div className='my-4'>
                  <CustomButton text={'カード情報を変更する'} handleOnClick={() => handleChange()} />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default PlanCard
