import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import { DataProvider } from './context/DataContext'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { jaJP } from '@mui/x-date-pickers/locales';
import { LocalStorageManager } from './util/localStorageManager';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#8F8E8A' },
      error: { main: '#923A3A' }
    },
  },
  jaJP,
);

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_BASEURL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['x-auth-koken'] = localStorage.getItem(LocalStorageManager.token);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={
        jaJP.components.MuiLocalizationProvider.defaultProps.localeText
      }
      adapterLocale="ja">
      <DataProvider>
        <App />
      </DataProvider>
    </LocalizationProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
