const CustomItem = ({ text, icon, handleClick }) => {
  return (
    <div className='input-box mt-3 cursor-pointer' onClick={handleClick}>
      <div className='ms-1 py-2 d-flex align-items-center'>
        <div className='color-gray-main text16' style={{ minWidth: '25px' }}>
          <i className={icon}></i>
        </div>
        <span className='color-gray-font'>{text}</span>
      </div>
    </div>
  )
}

export default CustomItem
