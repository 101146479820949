import CustomButton from './CustomButton'
import TopHeader from './TopHeader'
import pageTitle from '../assets/conectic.svg'

const IntroduceContent = ({ title, image, order, handleOnClick, text, width, returnFunc }) => {
  return (
    <>
      <TopHeader header={pageTitle} returnFunc={returnFunc} />
      <div className='grid-3-row bg-default'>
        <div className='text20 color-gray-accent text-center text-medium pre-wrap h100 mt-5'>{title}</div>
        <div className='text-center'>
          <img src={image} width={width} alt='introduce' />
        </div>
        <div className='px-5'>
          <div className='text-center color-gray-main text24'>
            <span className={order === 2 ? '' : 'disabled'}>•</span>
            <span className={order === 3 ? 'px-2' : 'px-2 disabled'}>•</span>
            <span className={order === 4 ? '' : 'disabled'}>•</span>
          </div>
          <CustomButton text={text} handleOnClick={handleOnClick} />
        </div>
      </div>
    </>
  )
}

export default IntroduceContent
