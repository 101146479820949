import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { ERROR_TEXT, POLICY_TEXT } from '../constant/constant'
import CustomButton from '../component/CustomButton'
import InputTextarea from './inputs/InputTextarea'
import InputText from './inputs/InputText'
import InputNumber from './inputs/InputNumber'
import InputModalSelect from './inputs/InputModalSelect'
import PostCodeSearchModal from './modals/PostCodeSearchModal'

const StoreInputComponent = ({ store, setStore, handleChange, isEdit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggle = () => setIsModalOpen(!isModalOpen)

  const [storeAddressModal, setStoreAddressModal] = useState(false)
  const toggleStoreAddressModal = () => setStoreAddressModal(!storeAddressModal)

  const [errors, setErrors] = useState({})

  const validate = () => {
    const newErrors = {}
    if (!store.storeName) newErrors.storeName = ERROR_TEXT.need
    if (!store.storeNumber) newErrors.storeNumber = ERROR_TEXT.need
    if (!store.storeAddress) newErrors.storeAddress = ERROR_TEXT.need
    if (!store.storeAccess) newErrors.storeAccess = ERROR_TEXT.need
    if (!store.storeOpening) newErrors.storeOpening = ERROR_TEXT.need
    if (!store.storeLast) newErrors.storeLast = ERROR_TEXT.need
    if (!store.storeClosed) newErrors.storeClosed = ERROR_TEXT.need
    if (!store.policy) newErrors.policy = ERROR_TEXT.need

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSave = (param) => {
    setStore({ ...store, storeAddress: param })
    toggleStoreAddressModal()
  }

  const determinePolicy = () => {
    const selectedOption = document.querySelector('input[name="store-policy"]:checked')
    if (selectedOption) {
      setStore({ ...store, policy: selectedOption.value })
    }
    toggle()
  }

  const handleSubmit = () => {
    if (validate()) {
      handleChange()
    }
  }

  return (
    <>
      <div>
        <InputText
          label={'店舗名'}
          name={'store-name'}
          placeholder={'あなたの店舗名'}
          value={store.storeName}
          handleChange={(e) => {
            setStore({ ...store, storeName: e.target.value })
            setErrors((prev) => ({ ...prev, storeName: undefined }))
          }}
          checkable={true}
          required={true}
        />
        {errors.storeName && <div className='error'>{errors.storeName}</div>}

        <InputNumber
          label={'店舗電話番号'}
          name={'store-number'}
          placeholder={'0120111111'}
          value={store.storeNumber.replace(/[^0-9]+/g, '')}
          handleChange={(e) => {
            const value = e.target.value.replace(/[^0-9]+/g, '')
            setStore({
              ...store,
              storeNumber: value,
            })
            setErrors((prev) => ({ ...prev, storeNumber: undefined }))
          }}
          checkable={true}
          required={true}
        />
        {errors.storeNumber && <div className='error'>{errors.storeNumber}</div>}

        <InputModalSelect label={'店舗住所'} value={store.storeAddress} handleClick={toggleStoreAddressModal} required={true} />
        {errors.storeAddress && <div className='error'>{errors.storeAddress}</div>}

        <InputText
          label={'アクセス'}
          name={'store-access'}
          placeholder={'東京メトロ渋谷駅から徒歩5分'}
          value={store.storeAccess}
          handleChange={(e) => {
            setStore({ ...store, storeAccess: e.target.value })
            setErrors((prev) => ({ ...prev, storeAccess: undefined }))
          }}
          checkable={true}
          required={true}
        />
        {errors.storeAccess && <div className='error'>{errors.storeAccess}</div>}

        <InputTextarea
          label={'営業時間'}
          name={'store-opening'}
          value={store.storeOpening}
          placeholder={'平日　10:00-18:00\n土日祝　9:00-19:00'}
          handleChange={(e) => {
            setStore({ ...store, storeOpening: e.target.value })
            setErrors((prev) => ({ ...prev, storeOpening: undefined }))
          }}
          required={true}
        />
        {errors.storeOpening && <div className='error'>{errors.storeOpening}</div>}

        <InputTextarea
          label={'最終受付時間'}
          name={'store-general-last'}
          value={store.storeLast}
          placeholder={'平日　17:00\n土日祝　18:00'}
          handleChange={(e) => {
            setStore({ ...store, storeLast: e.target.value })
            setErrors((prev) => ({ ...prev, storeLast: undefined }))
          }}
          required={true}
        />
        {errors.storeLast && <div className='error'>{errors.storeLast}</div>}

        <InputText
          label={'定休日'}
          name={'store-closed'}
          value={store.storeClosed}
          placeholder={'第3木曜日'}
          handleChange={(e) => {
            setStore({ ...store, storeClosed: e.target.value })
            setErrors((prev) => ({ ...prev, storeClosed: undefined }))
          }}
          checkable={true}
          required={true}
        />
        {errors.storeClosed && <div className='error'>{errors.storeClosed}</div>}

        <InputModalSelect label={'キャンセルポリシー'} value={store.policy} text={POLICY_TEXT[store.policy - 1]} handleClick={toggle} required={true} />
        {errors.policy && <div className='error'>{errors.policy}</div>}

        <div className='my-5'>
          <CustomButton text={isEdit ? '更新' : '次へ'} handleOnClick={handleSubmit} />
        </div>
      </div>

      <Modal isOpen={isModalOpen} toggle={toggle} className='policy-modal' fade={false}>
        <ModalBody>
          <div className='color-gray-accent text16'>キャンセルポリシーについて</div>
          <div className='mt-3 mb-4 text16 text-medium color-gray-font'>
            予約をキャンセルする場合に発生するキャンセル料です。
            <br />
            （支払い予約を利用されるお客様のみ）
            <br />
            以下の３つから設定することができます。
          </div>
          <div className='policy-options'>
            {POLICY_TEXT.map((text, index) => (
              <div
                key={index}
                className='policy-option d-flex align-items-center cursor-pointer mt-4'
                onClick={() => {
                  setStore({ ...store, policy: (index + 1).toString() })
                  setErrors((prev) => ({ ...prev, policy: undefined }))
                }}
              >
                <div className='policy-icon color-gray-main text20'>
                  <i className={`fa-regular ${store.policy === (index + 1).toString() ? 'fa-circle-dot' : 'fa-circle'} me-3`}></i>
                </div>
                <div className={`policy-text ${store.policy === (index + 1).toString() ? 'color-gray-font' : 'color-gray text-medium'} pre-wrap text16`}>{text}</div>
              </div>
            ))}
          </div>
          <div className='mt-5 mb-3'>
            <CustomButton text={'決定'} handleOnClick={determinePolicy} />
          </div>
        </ModalBody>
      </Modal>

      <PostCodeSearchModal isOpen={storeAddressModal} value={store.storeAddress} toggle={toggleStoreAddressModal} handleSave={handleSave} />
    </>
  )
}

export default StoreInputComponent
