import React, { useEffect, useState } from 'react'
import TopHeader from '../../component/TopHeader'
import axios from 'axios'
import { LocalStorageManager } from '../../util/localStorageManager'
import RegistrantInputComponent from '../../component/RegistrantInputComponent'

const RegistrantInfo = () => {
  const [store, setStore] = useState({
    userId: '',
    registrantName: '',
    registrantNumber: '',
    registrantAddress: '',
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const storeData = LocalStorageManager.getObjectItem(LocalStorageManager.store)
    setStore({
      userId: storeData.userId,
      registrantName: storeData.registrantName,
      registrantNumber: storeData.registrantNumber,
      registrantAddress: storeData.registrantAddress,
    })
  }, [])

  const handleChange = () => {
    if (isSubmitting) return
    setIsSubmitting(true)

    if (!Object.values(store).every((value) => value)) {
      return
    }
    axios
      .post('/api/registrantChange', store)
      .then((res) => {
        LocalStorageManager.setObjectItem(LocalStorageManager.store, res.data.store)
        alert('登録情報が変更されました。')
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'登録情報変更'} value={'/setting'} />
      <div className='grid-default-header pt-0'>
        <RegistrantInputComponent store={store} setStore={setStore} handleChange={handleChange} isEdit={true} />
      </div>
    </div>
  )
}

export default RegistrantInfo
