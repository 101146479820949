import { useState, useEffect } from 'react'
import postal_code from 'japan-postal-code'
import { Modal, ModalBody } from 'reactstrap'
import CustomButton from '../../component/CustomButton'
import { ERROR_TEXT } from '../../constant/constant'

const PostCodeSearchModal = ({ isOpen, toggle, value, handleSave }) => {
  const [pCode, setPCode] = useState('')
  const [address, setAddress] = useState('')
  const [subAddress, setSubAddress] = useState('')
  const [valid, setValid] = useState(true)

  useEffect(() => {
    if (value) {
      const data = value.split('\n')
      if (data[0]) {
        setAddress(data[0])
        setValid(true)
      }
      if (data[1]) setSubAddress(data[1])
    }
    // eslint-disable-next-line
  }, [value])

  const handleSearchPostalCode = () => {
    if (pCode.length === 7) {
      postal_code.get(pCode, (res) => {
        if (res) {
          setAddress(res.prefecture + res.city + res.area)
          setValid(true)
        }
      })
    } else {
      alert('郵便番号を正確に入力してください。')
    }
  }

  const saveAddress = () => {
    if (address === '') {
      alert('郵便番号を入力して住所を検索してください。')
      setValid(false)
      return
    }
    handleSave(address + '\n' + subAddress)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className='policy-modal round-modal' fade={false}>
      <ModalBody>
        <div className='color-gray-accent ms-1 mb-3'>店舗住所</div>
        <div className='input-box mt-2'>
          <div className='color-gray-main text-medium mb-1 ms-1'>郵便番号</div>
          <div className='d-flex justify-content-between'>
            <input className='custom-input' inputMode='numeric' name='postal-code' placeholder='0000000' value={pCode} onChange={(e) => setPCode(e.target.value)} />
            <div className='d-flex align-items-center ms-2 cursor-pointer color-gray-accent text-medium' onClick={() => handleSearchPostalCode()}>
              <i className='fa fa-search me-2' />
              <span className='text-nowrap'>住所を検索</span>
            </div>
          </div>
        </div>
        <div className='input-box mt-3'>
          <div className='color-gray-main text-medium mb-1 ms-1'>住所</div>
          <div className='d-flex justify-content-between'>
            <input
              className='custom-input'
              name='address'
              value={address}
              onChange={(e) => {
                setAddress(e.target.value)
                setValid(e.target.value ? true : false)
              }}
            />
          </div>
        </div>
        {!valid && <div className='error'>{ERROR_TEXT.need}</div>}
        <div className='input-box mt-3'>
          <div className='color-gray-main text-medium mb-1 ms-1'>番地・ビル名</div>
          <div className='d-flex justify-content-between'>
            <input className='custom-input' name='sub-address' value={subAddress} onChange={(e) => setSubAddress(e.target.value)} />
          </div>
        </div>
        <div className='mt-5 mb-3'>
          <CustomButton text={'決定'} handleOnClick={() => saveAddress()} />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default PostCodeSearchModal
