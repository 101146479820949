import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import TopHeader from '../../component/TopHeader'
import { LocalStorageManager } from '../../util/localStorageManager'
import CustomButton from '../../component/CustomButton'
import InputPhoneNumber from '../../component/inputs/InputPhoneNumber'
import InputPassword from '../../component/inputs/InputPassword'

const Register = () => {
  const navigate = useNavigate()
  const [isShow, setIsShow] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const userRegister = () => {
    if (isSubmitting) return
    setIsSubmitting(true)

    if (phoneNumber === '' || password === null) {
      return
    }

    const params = {
      phoneNumber: phoneNumber,
      password: password,
    }
    axios
      .post('/api/register', params)
      .then((res) => {
        if (res.data) {
          const user = res.data.user
          LocalStorageManager.setObjectItem(LocalStorageManager.tempUser, {
            userId: user._id,
            phoneNumber: user.phoneNumber,
            password: password,
          })
        }
        navigate('/register-sent')
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const handlePasswordShow = () => {
    if (password) {
      setIsShow(!isShow)
    }
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'新規登録'} value={'/'} />
      <div className='grid-default'>
        <div className='mb-5'>
          <InputPhoneNumber handleChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]+/g, ''))} />
          <InputPassword isShow={isShow} handlePasswordShow={handlePasswordShow} password={password} handleChange={(e) => setPassword(e.target.value)} />
          <div className='mt-3 mx-2 color-gray-accent text-medium'>
            アカウントを新規作成することで、
            <a className='text-link' href='https://billowy-eggnog-f9d.notion.site/733076d011ba4963bf16f483972fabf4'>
              利用規約
            </a>
            と
            <a className='text-link' href='https://billowy-eggnog-f9d.notion.site/78c3bf7d23ac4a028029066bfd8b7f8b'>
              プライバシーポリシー
            </a>
            に同意するものとみなします。
          </div>
        </div>
        <div className='text-center my-3 text-medium'>認証コードを送信します！</div>
        <CustomButton text={'次へ'} handleOnClick={() => userRegister()} disabled={!(password && phoneNumber)} />
      </div>
    </div>
  )
}

export default Register
