import React, { useState } from 'react'
import InputText from './inputs/InputText'
import InputNumber from './inputs/InputNumber'
import InputModalSelect from './inputs/InputModalSelect'
import PostCodeSearchModal from './modals/PostCodeSearchModal'
import CustomButton from '../component/CustomButton'
import { ERROR_TEXT } from '../constant/constant'

const RegistrantInputComponent = ({ store, setStore, handleChange, isEdit }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const toggle = () => setIsOpen(!isOpen)

  const validate = () => {
    const newErrors = {}
    if (!store.registrantName) newErrors.registrantName = ERROR_TEXT.need
    if (!/^\d{11}$/.test(store.registrantNumber)) newErrors.registrantNumber = ERROR_TEXT.phone
    if (!store.registrantNumber) newErrors.registrantNumber = ERROR_TEXT.need
    if (!store.registrantAddress) newErrors.registrantAddress = ERROR_TEXT.need

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSave = (param) => {
    setStore({ ...store, registrantAddress: param })
    toggle()
  }

  const handleSubmit = () => {
    if (validate()) {
      handleChange()
    }
  }

  return (
    <>
      <InputText
        label={'登録者名'}
        name={'registrant-name'}
        placeholder={'山田 花子'}
        value={store.registrantName}
        handleChange={(e) => {
          setStore({ ...store, registrantName: e.target.value })
          setErrors((prev) => ({ ...prev, registrantName: undefined })) // Clear error
        }}
        checkable={true}
        required={true}
      />
      {errors.registrantName && <div className='error'>{errors.registrantName}</div>}

      <InputNumber
        label={'電話番号'}
        name={'registrant-number'}
        placeholder={'09012345678'}
        value={store.registrantNumber}
        handleChange={(e) => {
          const value = e.target.value.replace(/[^0-9]+/g, '')
          setStore({ ...store, registrantNumber: value })
          setErrors((prev) => ({ ...prev, registrantNumber: undefined })) // Clear error
        }}
        checkable={true}
        required={true}
      />
      {errors.registrantNumber && <div className='error'>{errors.registrantNumber}</div>}

      <InputModalSelect label={'住所'} value={store.registrantAddress} handleClick={toggle} required={true} />
      {errors.registrantAddress && <div className='error'>{errors.registrantAddress}</div>}

      <div className='mt-4'></div>
      <CustomButton text={isEdit ? '変更を申請' : '次へ'} handleOnClick={handleSubmit} />
      <PostCodeSearchModal isOpen={isOpen} value={store.registrantAddress} toggle={toggle} handleSave={handleSave} />
    </>
  )
}

export default RegistrantInputComponent
