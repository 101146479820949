import React from "react";
import { useNavigate } from "react-router-dom";
import TopHeader from "../../component/TopHeader";
import bgBuilding from "../../assets/bank.svg";
import CustomButton from "../../component/CustomButton";
// import axios from 'axios';
// import { LocalStorageManager } from '../../util/localStorageManager';

const TransferDestinationComplete = () => {
  const navigate = useNavigate();
  // const param = useParams();
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const stepNext = () => {
    navigate("/card-check");
  };

  // const requestCardBrand = () => {
  //     if (!param) {
  //         return;
  //     }
  //     if (isSubmitting) return;
  //     setIsSubmitting(true);
  //     const store = LocalStorageManager.getObjectItem(LocalStorageManager.store);
  //     axios.post("/api/getApplicationUrls", { tenantId: store.tenantId })
  //         .then((res) => {
  //             window.location = res.data.url + process.env.REACT_APP_RETURN_URL;
  //         }).catch((error) => {
  //             alert(error.response?.data?.message ?? "エラー");
  //             console.log(error)
  //         }).finally(() => {
  //             setIsSubmitting(false)
  //         })
  // }

  return (
    <div className="default-container bg-default m-auto">
      <TopHeader
        pageTitle={"振り込み先登録完了"}
        value={"/my-page"}
        isGo={true}
      />
      <div className="grid-default pt-0 grid-half">
        <div className="text-center my-auto">
          <img src={bgBuilding} alt="conectic"></img>
        </div>
        <div className="mt-4">
          <div className="color-gray-sub text18 text-center mb-4">
            振り込み情報を登録しました。
          </div>
          <CustomButton
            text={"カードブランドの設定へ"}
            handleOnClick={() => stepNext()}
          />
        </div>
      </div>
    </div>
  );
};

export default TransferDestinationComplete;
