import React, { useEffect, useState } from 'react'
import axios from 'axios'
import TopHeader from '../../component/TopHeader'
import { LocalStorageManager } from '../../util/localStorageManager'
import StoreInputComponent from '../../component/StoreInputComponent'

const StoreInfo = () => {
  const [store, setStore] = useState({
    userId: '',
    storeName: '',
    storeNumber: '',
    storeAddress: '',
    storeAccess: '',
    storeOpening: '',
    storeLast: '',
    storeClosed: '',
    policy: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const storeData = LocalStorageManager.getObjectItem(LocalStorageManager.store)
    setStore({
      userId: storeData.userId,
      storeName: storeData.storeName,
      storeNumber: storeData.storeNumber,
      storeAddress: storeData.storeAddress,
      storeAccess: storeData.storeAccess,
      storeOpening: storeData.storeOpening,
      storeLast: storeData.storeLast,
      storeClosed: storeData.storeClosed,
      policy: storeData.policy,
    })
  }, [])

  const handleChange = () => {
    if (isSubmitting) return
    setIsSubmitting(true)

    if (!Object.values(store).every((value) => value)) {
      return
    }
    axios
      .post('/api/storeChange', store)
      .then((res) => {
        LocalStorageManager.setObjectItem(LocalStorageManager.store, res.data.store)
        alert('店舗情報が変更されました。')
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'店舗情報'} value={'/my-page'} />
      <div className='grid-default-header pt-0 overflow-y'>
        <StoreInputComponent store={store} setStore={setStore} handleChange={handleChange} isEdit={true} />
        <div className='py-2'></div>
      </div>
    </div>
  )
}

export default StoreInfo
