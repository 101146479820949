import React, { useState, useEffect } from 'react'
import TopHeader from '../../component/TopHeader'
import BookItems from '../../component/BookItems'
import { LocalStorageManager } from '../../util/localStorageManager'
import axios from 'axios'
import { convertHistoryDateTime, getDueDate, getParamDueDate, compareDateMonth, replaceText } from '../../util/convert'
import { useParams } from 'react-router-dom'
import NotSendModal from '../../component/modals/NotSendModal'

const SendingDetail = () => {
  const param = useParams()
  const [isShow, setIsShow] = useState(false)
  const [books, setBooks] = useState([])
  const [count, setCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  useEffect(() => {
    const store = LocalStorageManager.getObjectItem(LocalStorageManager.store)
    const { year, month } = param.id ? getParamDueDate(2, param.id.split('_')[0]) : getDueDate(2)
    const params = {
      storeId: store._id,
      year: year,
      month: month,
    }

    axios
      .post('/api/books/totalCount', params)
      .then((res) => {
        const groupedData = {}
        let num = 0
        res.data.books.forEach((appointment) => {
          const date = convertHistoryDateTime(appointment.updated_dt)
          if (groupedData[date]) {
            groupedData[date].push(appointment)
          } else {
            groupedData[date] = [appointment]
          }
          if (appointment.paymentMethod === 1) {
            num++
          }
        })
        setTotalCount(res.data.total)
        setCount(num)

        const updatedBooks = {}

        for (const key in groupedData) {
          if (updatedBooks.hasOwnProperty(key)) {
            updatedBooks[key] = [...updatedBooks[key], ...groupedData[key]]
          } else {
            updatedBooks[key] = groupedData[key]
          }
        }
        setBooks(updatedBooks)
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
  }, [param])

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={param.id?.split('_')[1] ? '振り込み詳細' : '売上詳細'} value={'/transfer'} />
      {
        <div className='grid-default-resize pb-5'>
          <div className='input-box-green-sm mt-3'>
            <div className='color-gray-accent text-medium text-center mb-2 mt-1'>
              {param.id ? getParamDueDate(0, param.id.split('_')[0]) : getDueDate(0)}分の売上額<i className='ms-1 fa-regular fa-circle-question' onClick={toggle}></i>
            </div>
            <div className='text-center mb-1'>
              <i className='fa fa-yen color-gray-accent text20 me-2'></i>
              <span className='roboto text28'>{replaceText(totalCount.toString())}</span>
            </div>
            <div className='color-red text-center mb-3'>
              <span className='color-gray-sub text-medium'>
                {param.id ? getParamDueDate(1, param.id.split('_')[0]) + (compareDateMonth(param.id.split('_')[0]) ? 'に振り込み予定' : 'に振り込み済み') : getDueDate(1) + 'に振り込み予定'}
              </span>
            </div>
            <div className='color-red text-center mb-2'>
              <div className='cursor-pointer' onClick={() => setIsShow(!isShow)}>
                <span className='color-gray-sub'>振り込み金額内訳</span>
                <i className={`fa ${isShow ? 'fa-angle-up' : 'fa-angle-down'} color-gray-sub ms-2`}></i>
              </div>
            </div>
            <div className={isShow ? '' : 'd-none'}>
              <div>
                <div className='color-gray-accent mb-1'>売上</div>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='color-gray-main text-medium'>顧客がカード支払いした金額</div>
                  <div>
                    <i className='fa-solid fa-yen color-gray-font me-1'></i>
                    <span className='roboto text18'>{replaceText(String(totalCount + 100 * count))}</span>
                  </div>
                </div>
                <hr className='color-gray-main'></hr>
              </div>
              <div>
                <div className='color-gray-accent mb-1'>差引</div>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='color-gray-main text-medium w200'>「クレジットカードの与信枠を確保し、キャンセルを防ぐ」を選択した回数と金額</div>
                  <div>
                    <i className='fa-solid fa-yen color-gray-font me-1'></i>
                    <span className='roboto text18'>-{replaceText(String(100 * count))}</span>
                    <span className='color-gray-main roboto-medium text18'> / {count}回</span>
                  </div>
                </div>
                <hr className='color-gray-main'></hr>
              </div>
              <div className='text-end'>
                <span className='color-gray-accent me-2'>振り込み予定額</span>
                <span className='color-gray-main text-medium me-2'>計</span>
                <i className='fa fa-yen text12 me-1'></i>
                <span className='text18 roboto me-1'>{replaceText(String(totalCount))}</span>
              </div>
            </div>
          </div>
          <div className='mt-4'>
            <BookItems books={books} url={'/transfer-book-detail/'} />
          </div>
        </div>
      }
      <NotSendModal isOpen={open} toggle={toggle} />
    </div>
  )
}

export default SendingDetail
