import React from 'react'
import { useNavigate } from 'react-router-dom'

const TopHeader = ({ pageTitle, header, value, isHide, returnFunc }) => {
  const navigate = useNavigate()

  return (
    <div className='top-white'>
      <div className='color-gray-accent text20'>{!isHide && <i className='p-1 fa fa-arrow-left cursor-pointer' onClick={returnFunc ? returnFunc : () => navigate(value)}></i>}</div>
      <div className='text-center color-gray-accent text20'>{header ? <img src={header} alt='header' /> : pageTitle}</div>
      <div>
        <div style={{ width: isHide ? '0px' : '20px' }}></div>
      </div>
    </div>
  )
}

export default TopHeader
