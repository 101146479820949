import React, { useState, useEffect } from 'react'
import TopHeader from '../../component/TopHeader'
import { useNavigate } from 'react-router-dom'
import { getParamDueDate } from '../../util/convert'
import { LocalStorageManager } from '../../util/localStorageManager'
import axios from 'axios'

const LastHistory = () => {
  const navigate = useNavigate()
  const [transfers, setTransfers] = useState([])

  useEffect(() => {
    const store = LocalStorageManager.getObjectItem(LocalStorageManager.store)
    axios
      .post('/api/getTransfers', { tenantId: store.tenantId })
      .then((res) => {
        setTransfers(res.data.transfers)
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
  }, [])

  const yearTotalCount = (key) => {
    return transfers[key].reduce((total, transfer) => total + transfer.amount, 0)
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'過去の振り込み額'} value={'/transfer'} />
      <div className='grid-default-resize pb-5'>
        {transfers &&
          Object.keys(transfers)
            .sort((a, b) => b - a)
            .map((key, index) => {
              return (
                <div key={index}>
                  <div className='mt-4'>
                    <div className='d-flex justify-content-between'>
                      <span className='ms-2 color-gray-main '>{key}年</span>
                      <div>
                        <span className='color-gray-main  me-2'>合計</span>
                        <i className='fa fa-yen color-gray-font me-1'></i>
                        <span className='roboto text18 me-2'>{yearTotalCount(key)}</span>
                      </div>
                    </div>
                    {transfers[key].map((item) => {
                      return (
                        <div className='input-box d-flex justify-content-between mt-3 cursor-pointer' onClick={() => navigate('/transfer-detail-last/' + item.schedule_date + '_last')} key={item.id}>
                          <div className='color-gray-font py-1 text-ellipsis'>{getParamDueDate(3, item.schedule_date)}月</div>
                          <div className='color-gray-font py-1'>
                            <i className='fa fa-yen me-1'></i>
                            <span className='roboto text18'>{item.amount}</span>
                            <i className='ms-2 fa fa-angle-right'></i>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
      </div>
    </div>
  )
}

export default LastHistory
