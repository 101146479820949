import React from 'react'

export const DataContext = React.createContext({})
export const DataProvider = ({ children }) => {
    return (
        <DataContext.Provider
            value={{}}
        >
            {children}
        </DataContext.Provider>
    )
}

export const useData = () => React.useContext(DataContext)
