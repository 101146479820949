import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Start from './pages/Start'
import Register from './pages/register/Register'
import RegisterSent from './pages/register/RegisterSent'
import RegisterVerify from './pages/register/RegisterVerify'
import RegisterGuide from './pages/register/RegisterGuide'
import Login from './pages/Login'
import StoreRegisterFirst from './pages/store/StoreRegisterFirst'
import StoreRegisterSecond from './pages/store/StoreRegisterSecond'
import StoreRegisterThird from './pages/store/StoreRegisterThird'
import StoreRegisterLast from './pages/store/StoreRegisterLast'
import Introduce from './pages/Introduce'
import Home from './pages/Home'
import AcceptBooking from './pages/booking/AcceptBooking'
import AcceptBookingComplete from './pages/booking/AcceptBookingComplete'
import HistoryList from './pages/history/HistoryList'
import HistoryDetail from './pages/history//HistoryDetail'
import BookList from './pages/booking/BookList'
import BookDetail from './pages/booking/BookDetail'
import Payment from './pages/booking/Payment'
import MyPage from './pages/setting/MyPage'
import StoreInfo from './pages/setting/StoreInfo'
import IdleWatch from './component/IdleWatch'
import Setting from './pages/setting/Setting'
import RegistrantInfo from './pages/setting/RegistrantInfo'
import ClientBook from './pages/booking/ClientBook'
import ClientBookComplete from './pages/booking/ClientBookComplete'
import Contacts from './pages/setting/Contacts'
import Sending from './pages/transfer/Sending'
import SendingDetail from './pages/transfer/SendingDetail'
import SendingBookDetail from './pages/transfer/SendingBookDetail'
import TransferDestination from './pages/transfer/TransferDestination'
import TransferDestinationComplete from './pages/transfer/TransferDestinationComplete'
import LastHistory from './pages/transfer/LastHistory'
import CardCheck from './pages/setting/CardCheck'
import PlanCheck from './pages/setting/PlanCheck'
import ChangePassword from './pages/password/ChangePassword'
import Password from './pages/password/Password'
import PlanCard from './pages/setting/PlanCard'
import DeleteAccount from './pages/setting/DeleteAccount'

function App() {
  useEffect(() => {
    const handleOnline = () => {
      alert('あなたは現在オンラインです!')
    }

    const handleOffline = () => {
      alert('あなたは現在オフラインです!')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  return (
    <>
      <BrowserRouter>
        <IdleWatch />
        <Routes>
          <Route path='/' element={<Start />} />
          <Route path='/register' element={<Register />} />
          <Route path='/register-sent' element={<RegisterSent />} />
          <Route path='/register-verify' element={<RegisterVerify />} />
          <Route path='/register-guide' element={<RegisterGuide />} />
          <Route path='/login' element={<Login />} />
          <Route path='/change-password' element={<ChangePassword />} />
          <Route path='/password/:id' element={<Password />} />
          <Route path='/introduce' element={<Introduce />} />
          <Route path='/store-register-first' element={<StoreRegisterFirst />} />
          <Route path='/store-register-second' element={<StoreRegisterSecond />} />
          <Route path='/store-register-third' element={<StoreRegisterThird />} />
          <Route path='/store-register-last' element={<StoreRegisterLast />} />
          <Route path='/home' element={<Home />} />
          <Route path='/accept-booking' element={<AcceptBooking />} />
          <Route path='/accept-booking/:id' element={<AcceptBooking />} />
          <Route path='/accept-booking-complete/:id' element={<AcceptBookingComplete />} />
          <Route path='/history' element={<HistoryList />} />
          <Route path='/history-detail/:id' element={<HistoryDetail />} />
          <Route path='/reservations' element={<BookList />} />
          <Route path='/reservations-detail/:id' element={<BookDetail />} />
          <Route path='/payment/:id' element={<Payment />} />
          <Route path='/my-page' element={<MyPage />} />
          <Route path='/store-info' element={<StoreInfo />} />
          <Route path='/registrant-info' element={<RegistrantInfo />} />
          <Route path='/delete-account' element={<DeleteAccount />} />
          <Route path='/setting' element={<Setting />} />
          <Route path='/book/:id' element={<ClientBook />} />
          <Route path='/book-complete/:id' element={<ClientBookComplete />} />
          <Route path='/card-check' element={<CardCheck />} />
          <Route path='/plan-check' element={<PlanCheck />} />
          <Route path='/plan-card' element={<PlanCard />} />
          <Route path='/to' element={<Contacts />} />
          <Route path='/transfer' element={<Sending />} />
          <Route path='/transfer-detail' element={<SendingDetail />} />
          <Route path='/transfer-detail-last' element={<SendingDetail />} />
          <Route path='/transfer-detail/:id' element={<SendingDetail />} />
          <Route path='/transfer-detail-last/:id' element={<SendingDetail />} />
          <Route path='/transfer-book-detail/:id' element={<SendingBookDetail />} />
          <Route path='/register-transfer-destination' element={<TransferDestination />} />
          <Route path='/change-transfer-destination' element={<TransferDestination />} />
          <Route path='/transfer-destination-complete' element={<TransferDestinationComplete />} />
          <Route path='/last-transfer-history' element={<LastHistory />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
