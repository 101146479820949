const InputModalSelect = ({ label, value, text, handleClick, icon, required }) => {
  return (
    <div className='input-box cursor-pointer mt-3' onClick={handleClick}>
      {icon ? (
        <div className='d-flex justify-content-between align-items-center'>
          {!value && (
            <>
              <div className='d-flex align-items-end'>
                <span className='color-gray-main me-2 text16'>
                  <i className={icon}></i>
                </span>
                <div className='color-gray text-medium ms-1'>
                  {label}
                  {required && <span className='color-red'>*</span>}
                </div>
              </div>
              <i className='fa fa-angle-down color-gray-accent'></i>
            </>
          )}
        </div>
      ) : (
        <div className='d-flex justify-content-between align-items-center'>
          <div className='color-gray-accent ms-1'>
            {label}
            {required && <span className='color-red'>*</span>}
          </div>
          {!value && <i className='fa fa-angle-down color-gray-accent'></i>}
        </div>
      )}
      <div className='mt-1 ms-1'>
        {value && (
          <div className='d-flex justify-content-between align-items-top'>
            <div className='color-gray-font pre-wrap text16'>{text ?? value}</div>
            <i className='fa fa-circle-check check-indicator ms-2'></i>
          </div>
        )}
      </div>
    </div>
  )
}

export default InputModalSelect
