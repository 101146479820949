export const convertDateTime = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
  }

  const date = new Date(dateString)
  const formattedDate = date.toLocaleString('ja-JP', options).replace(/日(.*)/, '日 $1') + '~'
  if (formattedDate === 'Invalid Date~') {
    return '日時が選択されていません。'
  }
  return formattedDate
}

export const convertPickerDateTime = (dateString) => {
  const date = new Date(dateString)
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    weekday: 'narrow',
    hour: '2-digit',
    minute: '2-digit',
  }
  const formattedDate = date.toLocaleString('ja-JP', options).replace(/(\(|\))/g, ' ')

  return formattedDate
}

export const replaceText = (text) => {
  return text.replace(/[^0-9]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const extractDate = (created_dt) => {
  const date = new Date(created_dt)
  return date.toLocaleDateString()
}

export const convertHistoryDateTime = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  }

  const date = new Date(dateString)
  const formattedDate = date.toLocaleDateString('ja-JP', options).replace(/日(.*)/, '日 $1')
  return formattedDate
}

export const convertDetailDateTime = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }

  const date = new Date(dateString)
  const formattedDate = date.toLocaleString('ja-JP', options).replace(/\//g, '/')
  return formattedDate
}

export const padNumber = (num, length) => {
  return num.toString().padStart(length, '0')
}

export const getDueDate = (type) => {
  const today = new Date()

  if (type === 0) {
    return `${today.getFullYear()}年${today.getMonth() + 1}月`
  } else if (type === 1) {
    const dueDate = today.getMonth() + 2 === 13 ? new Date(today.getFullYear() + 1, 1, 0) : new Date(today.getFullYear(), today.getMonth() + 2, 0)
    return `${dueDate.getMonth() + 1}月${dueDate.getDate()}日`
  } else {
    return {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
    }
  }
}

export const getParamDueDate = (type, date) => {
  const today = new Date(date)
  if (type === 0) {
    return `${today.getFullYear()}年${today.getMonth() === 0 ? 12 : today.getMonth()}月`
  } else if (type === 1) {
    return `${today.getMonth() + 1}月${today.getDate()}日`
  } else if (type === 2) {
    return {
      year: today.getFullYear(),
      month: today.getMonth() === 0 ? 12 : today.getMonth(),
    }
  } else {
    return today.getMonth() === 0 ? 12 : today.getMonth()
  }
}

export const compareDateMonth = (date) => {
  const today = new Date()
  const paramDate = new Date(date)
  return today.getMonth() === paramDate.getMonth()
}
