import React from 'react'
import TopHeader from '../../component/TopHeader'
import CustomItem from '../../component/CustomItem'

const { REACT_APP_CONTACT_LINE, REACT_APP_CONTACT_EMAIL } = process.env

const Contacts = () => {
  const handleLineClick = () => {
    window.open('https://page.line.me/' + REACT_APP_CONTACT_LINE + '?oat_content=url&openQrModal=true', '_blank')
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'運営へ連絡'} value={'/setting'} />
      <div className='grid-default-resize'>
        <CustomItem text={'メール'} icon={'fa-regular fa-envelope'} handleClick={() => (window.location.href = 'mailto:' + REACT_APP_CONTACT_EMAIL)} />
        <CustomItem text={'LINEメッセージ'} icon={'fa-brands fa-line'} handleClick={handleLineClick} />
      </div>
    </div>
  )
}

export default Contacts
