import React from 'react'
import { useNavigate } from 'react-router-dom'
import TopHeader from '../../component/TopHeader'
import { LocalStorageManager } from '../../util/localStorageManager'
import CustomItem from '../../component/CustomItem'

const MyPage = () => {
  const navigate = useNavigate()
  const stepNext = (url) => {
    const user = LocalStorageManager.getObjectItem(LocalStorageManager.user)
    if (url === '/transfer') {
      if (!user.planStatus) {
        alert('プランが終了しました。設定でご確認ください。')
        return
      }
    }
    navigate(url)
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'マイページ'} value={'/home'} />
      <div className='grid-default-resize'>
        <CustomItem text={'店舗情報'} icon={'fa fa-shop'} handleClick={() => stepNext('/store-info')} />
        <CustomItem text={'売上管理'} icon={'fa-regular fa-credit-card'} handleClick={() => stepNext('/transfer')} />
        <CustomItem text={'設定'} icon={'fa-regular fa-credit-card'} handleClick={() => stepNext('/setting')} />
      </div>
    </div>
  )
}

export default MyPage
