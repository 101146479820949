import Visa from "../assets/brands/icon_visa.png";
import MasterCard from "../assets/brands/icon_mastercard.png";
import JCB from "../assets/brands/icon_jcb.png";
import Discover from "../assets/brands/icon_discover.png";
import AmericanExpress from "../assets/brands/icon_americanexpress.png";
import DinersClub from "../assets/brands/icon_dinersclub.png";

export const getImage = (name) => {
  switch (name) {
    case "Visa":
      return Visa;
    case "MasterCard":
      return MasterCard;
    case "JCB":
      return JCB;
    case "Discover":
      return Discover;
    case "AmericanExpress":
      return AmericanExpress;
    case "DinersClub":
      return DinersClub;
    default:
      break;
  }
};

export const getHeight = (name) => {
  switch (name) {
    case "Visa":
      return "12px";
    case "MasterCard":
      return "28px";
    case "JCB":
      return "26px";
    case "Discover":
      return "20px";
    case "AmericanExpress":
      return "20px";
    case "DinersClub":
      return "22px";
    default:
      break;
  }
};
