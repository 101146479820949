import React, { useEffect, useState } from 'react'
import BookingHeader from '../../component/BookingHeader'
import { useNavigate } from 'react-router-dom'
import { LocalStorageManager } from '../../util/localStorageManager'
import axios from 'axios'
import { getDueDate, replaceText } from '../../util/convert'
import CustomItem from '../../component/CustomItem'
import NotSendModal from '../../component/modals/NotSendModal'

const Sending = () => {
  const navigate = useNavigate()
  const [totalCount, setTotalCount] = useState(0)
  const [isRegistered, setIsRegistered] = useState(null)
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  useEffect(() => {
    const store = LocalStorageManager.getObjectItem(LocalStorageManager.store)
    const date = getDueDate(3)
    const param = {
      storeId: store._id,
      year: date.year,
      month: date.month,
    }
    axios
      .post('/api/books/totalCount', param)
      .then((res) => {
        setTotalCount(res.data.total)
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })

    setIsRegistered(store.tenantId ? true : false)
  }, [])

  return (
    <div className='default-container bg-default m-auto'>
      <BookingHeader pageTitle={'売上管理'} value={'/my-page'} />
      {isRegistered !== null && (
        <div className='grid-default-resize mt--35 pb-5'>
          <div className='input-box mt-3'>
            <div className='color-gray-main text-medium mb-1'>
              {getDueDate(0)}分の売上額
              <i className='ms-1 fa-regular fa-circle-question' onClick={toggle}></i>
            </div>
            <div className='d-flex justify-content-between align-items-center mb-1'>
              <div>
                <i className='fa fa-yen color-gray-accent text18 me-2'></i>
                <span className='roboto text24'>{replaceText(totalCount.toString())}</span>
              </div>
              <div>
                <span className='color-gray-accent text-medium cursor-pointer' onClick={() => navigate('/transfer-detail')}>
                  売上詳細
                </span>
                <i className='fa fa-angle-right color-gray-accent ms-2'></i>
              </div>
            </div>
            {!isRegistered && <div className='color-red text-medium text-center mb-1'>振り込み先を登録してください</div>}
          </div>
          {isRegistered ? (
            <>
              <CustomItem text={'過去の振り込み額'} icon={'fa fa-clock-rotate-left'} handleClick={() => navigate('/last-transfer-history')} />
              <CustomItem text={'振り込み情報変更'} icon={'fa fa-gear'} handleClick={() => navigate('/change-transfer-destination')} />
            </>
          ) : (
            <div className='input-box mt-3 cursor-pointer' onClick={() => navigate('/register-transfer-destination')}>
              <div className='color-gray-font ms-1 py-2'>
                <span className='color-gray-sub text18'>
                  <i className='fa fa-gear me-3'></i>
                </span>
                <span className='color-red '>振り込み先登録</span>
              </div>
            </div>
          )}
        </div>
      )}
      <NotSendModal isOpen={open} toggle={toggle} />
    </div>
  )
}

export default Sending
