import React from 'react'
import { useNavigate } from 'react-router-dom'

const GreenHeader = ({ pageTitle, isHide, value, name, isContact }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (isContact) {
      window.location.reload()
      return
    }
    navigate(value)
  }

  return (
    <>
      <div className='top-green' style={{ height: isContact ? '95px' : '100px' }}>
        <div className='text-white text20'>{!isHide && <i className='p-1 fa fa-arrow-left cursor-pointer' onClick={() => handleClick()}></i>}</div>
        <div className='text20 text-white text-center'>{pageTitle}</div>
        <div style={{ width: isHide ? '0px' : '20px' }}></div>
      </div>
      {isContact && <div className='text-white text20 bg-gray-main px-4 pb-3'>{name}</div>}
    </>
  )
}

export default GreenHeader
