import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TopHeader from '../../component/TopHeader'
import axios from 'axios'
import { decrypt, encrypt } from '../../util/crypto'
import DetailComponent from '../../component/DetailComponent'
import { BOOK_STATUS } from '../../constant/constant'
import CustomButton from '../../component/CustomButton'
import outlinedError from '../../assets/error_outline.svg'
import { Modal, ModalBody } from 'reactstrap'

const BookDetail = () => {
  const navigate = useNavigate()
  const param = useParams()
  const [detail, setDetail] = useState(null)
  const [isDeleted, setIsDeleted] = useState(false)
  const [deleteMode, setDeleteMode] = useState(false)
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  useEffect(() => {
    if (!param.id) {
      return
    }
    axios
      .get(`/api/books/${decrypt(param.id)}`)
      .then((res) => {
        if (res.data && res.data.detail) {
          setDetail(res.data.detail)
        }
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
  }, [param])

  const handleBookDelete = () => {
    axios
      .post(`/api/bookDelete`, { id: decrypt(param.id) })
      .then((res) => {
        setDetail(res.data.detail)
        setDeleteMode(false)
        setIsDeleted(true)
        setTimeout(() => {
          setIsDeleted(false)
        }, 3000)
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
  }

  const handleMoveToPayment = () => {
    if (detail.status === BOOK_STATUS.booked && detail.paymentMethod !== 3) {
      alert('お客様の事前決済が行われていません。')
      return
    }
    navigate(`/payment/${encrypt(detail._id)}`)
  }

  return (
    <div className='default-container bg-default m-auto' style={{ position: 'relative' }}>
      <TopHeader pageTitle={'予約詳細'} value={'/reservations'} />
      <div className='grid-default-resize pt-2'>
        {deleteMode && <div className='color-red  mb-2 ms-1'>以下の予約をキャンセルします。</div>}
        <DetailComponent detail={detail} />
        {deleteMode && (
          <div className='pb-5'>
            <div className='input-box-green-sm mt-4 mb-4' style={{ background: 'transparent' }}>
              <div className='cancel-text mb-2'>
                <span className='color-red me-2'>
                  <img src={outlinedError} alt='error_outline' />
                </span>
                <span className='color-gray-font '>キャンセルを行うと顧客に通知されます。</span>
              </div>
              <div className='cancel-text'>
                <span className='color-red me-2'>
                  <img src={outlinedError} alt='error_outline' />
                </span>
                <span className='color-gray-font '>顧客が事前決済を行っている場合、予約キャンセルと同時に与信枠もリリースされます。</span>
              </div>
            </div>
            <CustomButton text='予約をキャンセルする' color={'error'} handleOnClick={() => handleBookDelete()} />
          </div>
        )}
        {detail && !deleteMode && (
          <div className='pb-4 mt-5'>
            {detail.status === BOOK_STATUS.payed ? (
              <CustomButton text='決済済み' disabled={true} />
            ) : detail.status === BOOK_STATUS.canceled ? (
              <CustomButton text='キャンセルされました' disabled={true} />
            ) : (
              <>
                {detail.price !== '' && detail.paymentMethod !== null && <CustomButton text='決済をする' handleOnClick={() => handleMoveToPayment()} />}
                <div className='text-center color-gray-main mt-3'>
                  <span className='cursor-pointer' onClick={toggle}>
                    予約のキャンセル・変更
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <Modal isOpen={open} toggle={toggle} className='alert-modal logout-modal' centered size='sm' fade={false}>
        <ModalBody>
          <div className='d-flex flex-column align-items-center m-3'>
            <div className='color-gray-main mb-5 text20 mb-4'>予約のキャンセル・変更</div>
            <CustomButton text='予約を変更してSMSを再送' handleOnClick={() => navigate(`/accept-booking/${encrypt(detail._id)}`)} />
            <div className='m-2'></div>
            <CustomButton
              text='予約をキャンセル'
              plain={true}
              handleOnClick={() => {
                toggle()
                setDeleteMode(true)
              }}
            />
            <div className='color-gray-main  mt-3'>
              <span onClick={toggle} className='p-2 cursor-pointer'>
                閉じる
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {isDeleted && <div className='deleted-bar text-white bottom-bar'>予約をキャンセルしました。</div>}
    </div>
  )
}

export default BookDetail
