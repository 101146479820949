import React, { useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { LocalStorageManager } from '../util/localStorageManager'
import { logout } from '../util/logout'

const noTokens = ['/', '/start', '/login', '/password', '/change-password', '/register', 'register-sent', '/register-guide', '/register-verify']

const noTokens2 = ['/book', '/book-complete', '/contact']

const IdleWatch = () => {
  const location = useLocation()

  const checkAuth = useCallback(async () => {
    if (noTokens.includes(location.pathname)) return
    let flag = false
    noTokens2.forEach((item) => {
      if (location.pathname.includes(item)) {
        flag = true
      }
    })
    if (flag) return
    try {
      const token = localStorage.getItem('token')
      const userString = localStorage.getItem('user')

      if (token && userString) {
        const user = JSON.parse(userString)
        await axios
          .post('/api/authToken', { token: token, userId: user._id })
          .then((res) => {
            LocalStorageManager.setItem(LocalStorageManager.token, res.data.token)
            axios.defaults.headers.common['x-auth-koken'] = res.data.token
          })
          .catch(() => {
            logout()
          })
      }
    } catch (error) {
      logout()
    }
  }, [location])

  useEffect(() => {
    checkAuth()
    const eventListener = () => checkAuth()
    document.addEventListener('click', eventListener)
    document.addEventListener('keydown', eventListener)

    return () => {
      document.removeEventListener('click', eventListener)
      document.removeEventListener('keydown', eventListener)
    }
  }, [checkAuth, location.pathname])

  return <React.Fragment />
}

export default IdleWatch
