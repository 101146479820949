import React from 'react'
import { convertDetailDateTime, replaceText } from '../util/convert'
import { BOOK_STATUS, METHOD_TEXT } from '../constant/constant'
import { handleCopy } from '../util/copy'
import CloneButton from './CloneButton'

const DetailComponent = ({ detail }) => {
  return (
    <>
      {detail && (
        <>
          <div className='d-flex justify-content-between'>
            <div className='color-gray-sub text16 text-medium ms-1'>
              <span className='roboto-medium text18 me-2'>{convertDetailDateTime(detail.created_dt)}</span>
              <span>送信</span>
            </div>
            {detail.status !== BOOK_STATUS.canceled && <CloneButton handleCopy={() => handleCopy(detail)} />}
          </div>
          <div className='input-box mt-1'>
            <div className='py-1'>
              <span className='text20 me-1'>{detail.customerName}</span>
              <span className='color-gray-font'>さま</span>
            </div>
            <div className='roboto color-gray-main'>{detail.customerNumber}</div>
          </div>
          <div className='input-box mt-3'>
            <div className='color-gray-main text-medium mb-1'>金額</div>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                {detail.price ? (
                  <i className='fa fa-yen me-1 text14'></i>
                ) : (
                  <span className='color-gray'>
                    <i className='fa fa-yen me-1 text14'></i>
                  </span>
                )}
                <span className={`${detail.price ? 'color-gray-font' : 'color-gray text-medium'} pre-wrap text18 roboto`}>{detail.price === '' ? '- -' : replaceText(detail.price)}</span>
              </div>
              {detail.status === BOOK_STATUS.payed && (
                <div className='check-indicator-3'>
                  <i className='fa fa-circle-check me-1'></i>
                  <span>決済済み</span>
                </div>
              )}
              {detail.status === BOOK_STATUS.verified && detail.status === BOOK_STATUS.checked && (
                <div className='check-indicator-3'>
                  <i className='fa fa-check me-1'></i>
                  <span>事前決済済み</span>
                </div>
              )}
              {detail.status === BOOK_STATUS.canceled && <div className='color-gray-main'>キャンセルされました</div>}
            </div>
          </div>
          <div className='input-box mt-3'>
            <div className={`${detail.paymentMethod ? 'color-gray-font' : 'color-gray text-medium'} pre-wrap text16`}>
              {detail.paymentMethod ? METHOD_TEXT[detail.paymentMethod - 1] : '決済方法未定'}
            </div>
          </div>
          <div className='input-box mt-3'>
            <div className='color-gray-main text-medium mb-1'>メッセージ</div>
            <div className={`${detail.product === '' ? 'color-gray text-medium' : 'color-gray-font'} pre-wrap text16`}>{detail.product === '' ? 'なし' : detail.product}</div>
          </div>
          <div className='input-box mt-3'>
            <div className='color-gray-main text-medium mb-1'>来店予定日</div>
            <div className='color-gray-font roboto text18 me-1'>{convertDetailDateTime(detail.dateTime)}</div>
          </div>
        </>
      )}
    </>
  )
}

export default DetailComponent
