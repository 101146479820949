import { Modal, ModalBody } from 'reactstrap'

const NotSendModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className='alert-modal logout-modal' centered size='sm' fade={false}>
      <ModalBody>
        <div className='d-flex flex-column align-items-center m-3'>
          <div className='color-gray-font mb-4 text-medium'>振込予定額が1,000円未満の場合は翌月以降に繰越となります。</div>
          <div className='color-gray '>
            <span onClick={toggle} className='p-2 cursor-pointer'>
              閉じる
            </span>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default NotSendModal
