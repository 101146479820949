import React, { useCallback, useEffect, useRef, useState } from "react";
import TopHeader from "../../component/TopHeader";
import axios from "axios";
import { convertHistoryDateTime } from "../../util/convert";
import BookItems from "../../component/BookItems";
import { LocalStorageManager } from "../../util/localStorageManager";
import InputSearch from "../../component/InputSearch";

const HistoryList = () => {
  const [history, setHistory] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowMore, setIsShowMore] = useState(true);
  const pageRef = useRef(0);
  const [searched, setSearched] = useState(false);
  const [value, setValue] = useState("");
  const [oldValue, setOldValue] = useState(null);

  const loadHistory = useCallback(() => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const user = LocalStorageManager.getObjectItem(LocalStorageManager.user);
    if (!searched || value !== oldValue) {
      pageRef.current = 0;
      setSearched(true);
      setOldValue(value);
    }
    pageRef.current = pageRef.current + 1;
    axios
      .post("/api/history", {
        pageNumber: pageRef.current,
        userId: user._id,
        search: value,
      })
      .then((res) => {
        if (res.data && res.data.history) {
          const groupedData = {};
          res.data.history.forEach((appointment) => {
            const date = convertHistoryDateTime(appointment.created_dt);
            if (groupedData[date]) {
              groupedData[date].push(appointment);
            } else {
              groupedData[date] = [appointment];
            }
          });

          const updatedHistory =
            !searched || value !== oldValue ? {} : { ...history };

          for (const key in groupedData) {
            if (updatedHistory.hasOwnProperty(key)) {
              updatedHistory[key] = [
                ...updatedHistory[key],
                ...groupedData[key],
              ];
            } else {
              updatedHistory[key] = groupedData[key];
            }
          }
          setHistory(updatedHistory);
          setIsShowMore(res.data.length > 9);
        }
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? "エラー");
        console.log(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [history, isSubmitting, searched, value, oldValue]);

  useEffect(() => {
    loadHistory();
    // eslint-disable-next-line
  }, []);

  const handleSearch = () => {
    loadHistory();
  };

  return (
    <div className="default-container bg-default m-auto">
      <TopHeader pageTitle={"予約内容の送信履歴"} value={"/home"} />
      <div className="grid-default-resize pt-2">
        <InputSearch
          value={value}
          setValue={setValue}
          handleSearch={handleSearch}
        />
        <BookItems books={history} url={"/history-detail/"} />
        {isShowMore && !isSubmitting && (
          <div className="color-gray-main text-center py-4">
            <span className="cursor-pointer" onClick={() => loadHistory()}>
              もっと見る
            </span>
          </div>
        )}
        <div className="p-4"></div>
      </div>
    </div>
  );
};

export default HistoryList;
