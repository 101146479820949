import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OtpInput from 'react18-input-otp'
import axios from 'axios'
import TopHeader from '../../component/TopHeader'
import { LocalStorageManager } from '../../util/localStorageManager'

const RegisterVerify = () => {
  const navigate = useNavigate()
  const [otp, setOtp] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const userRegisterCodeResend = () => {
    if (isSubmitting) return
    setIsSubmitting(true)

    const user = LocalStorageManager.getObjectItem(LocalStorageManager.tempUser)
    const params = {
      userId: user.userId,
      phoneNumber: user.phoneNumber,
      password: '1234qwer',
    }
    axios
      .post('/api/register', params)
      .then(() => {
        navigate('/register-sent')
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'ERROR')
        console.log(error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  useEffect(() => {
    if (otp.length === 6) {
      const user = LocalStorageManager.getObjectItem(LocalStorageManager.tempUser)
      const params = {
        phoneNumber: user.phoneNumber,
        confirmCode: otp,
      }
      axios
        .post('/api/verify', params)
        .then((res) => {
          if (res.data) {
            const { user, token } = res.data
            LocalStorageManager.setObjectItem(LocalStorageManager.user, user)
            LocalStorageManager.setItem(LocalStorageManager.token, token)
            LocalStorageManager.removeItem(LocalStorageManager.tempUser)
          }
          navigate('/introduce')
        })
        .catch((error) => {
          alert(error.response?.data?.message ?? 'ERROR')
          console.log(error)
        })
    }
  }, [otp, navigate])

  const handleChange = (e) => {
    setOtp(e)
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'認証コード入力'} value={'/register-sent'} />
      <div className='grid-default'>
        <div className='text-center color-gray-font mb-5 text-medium '>認証コード（6桁の数字）を入力してください。</div>
        <div className='pb-3 mb-5 otp text-center'>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            isInputNum={true}
            shouldAutoFocus={true}
            renderSeparator={''}
            placeholder={'000000'}
            renderInput={(props) => <input {...props} inputMode='numeric' />}
          />
        </div>
        <div className='text-center color-gray-accent mb-3 nt-5'>
          <span className='cursor-pointer' onClick={() => userRegisterCodeResend()}>
            メッセージを再送信する
          </span>
        </div>
        <div className='text-center color-gray-sub my-4'>
          <span className='cursor-pointer' onClick={() => navigate('/register-guide')}>
            メッセージが届かない場合
          </span>
        </div>
      </div>
    </div>
  )
}

export default RegisterVerify
