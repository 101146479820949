import React from 'react'
import { useNavigate } from 'react-router-dom'
import salon from '../assets/salon.svg'
import CustomButton from '../component/CustomButton'

const Start = () => {
  const navigate = useNavigate()

  return (
    <div className='start-container'>
      <div className='grid-start bg-start'>
        <div className='m-auto d-grid h-100 align-items-end'>
          <img src={salon} alt='conectic' />
        </div>
        <div className='px-5'>
          <CustomButton text={'ログイン'} handleOnClick={() => navigate('/login')} />
          <div className='text-center color-gray-main  cursor-pointer my-4' onClick={() => navigate('/register')}>
            新規登録
          </div>
        </div>
      </div>
      <div className='start-footer color-gray-main'>
        <div onClick={() => (window.location.href = 'https://billowy-eggnog-f9d.notion.site/104f3769ab2c803da491df44d8977a0d')}>特定商取引法に基づく表示</div>
        <div onClick={() => (window.location.href = 'https://billowy-eggnog-f9d.notion.site/78c3bf7d23ac4a028029066bfd8b7f8b')}>プライバシーポリシー</div>
      </div>
    </div>
  )
}

export default Start
