import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import TopHeader from '../../component/TopHeader';
import axios from 'axios';
import { decrypt, encrypt } from '../../util/crypto';
import DetailComponent from '../../component/DetailComponent';
import { BOOK_STATUS } from '../../constant/constant';
import CustomButton from '../../component/CustomButton';

const HistoryDetail = () => {

    const navigate = useNavigate();
    const param = useParams();
    const [detail, setDetail] = useState(null);

    useEffect(() => {
        if (!param.id) {
            return;
        }
        axios.get(`/api/history/${decrypt(param.id)}`)
            .then((res) => {
                if (res.data && res.data.detail) {
                    setDetail(res.data.detail)
                }
            }).catch((error) => {
                alert(error.response?.data?.message ?? "エラー");
                console.log(error)
            })
    }, [param])

    return (
        <div className='default-container bg-default m-auto'>
            <TopHeader pageTitle={'予約詳細'} value={'/history'} />
            <div className='grid-default-resize pt-2'>
                <DetailComponent detail={detail} />
                {detail &&
                    <div className='pb-4 mt-5'>
                        {detail.status === BOOK_STATUS.payed
                            ? <CustomButton text={'決済済み'} disabled={true} />
                            : (detail.status === BOOK_STATUS.verified || detail.status === BOOK_STATUS.checked)
                                ? <CustomButton text={'事前決済済み'} disabled={true} />
                                : detail.status === BOOK_STATUS.canceled
                                    ? <CustomButton text={'キャンセルされました'} disabled={true} />
                                    : <>
                                        <CustomButton text={'予約を変更して再送'} handleOnClick={() => navigate(`/accept-booking/${encrypt(detail._id)}`)} />
                                    </>
                        }
                    </div>
                }
            </div>
        </div >
    )
}

export default HistoryDetail