const InputPassword = ({ isShow, handlePasswordShow, password, handleChange }) => {
  return (
    <div className='input-box mt-4'>
      <div className={`color-gray-accent ms-1 mb-1 ${password ? '' : 'text-medium'}`}>パスワード</div>
      {!password && <div className='d-block m-0 ms-1 mb-1 text12 color-gray text-medium'>英数字を含む8文字以上で入力してください</div>}
      <div className='d-flex align-items-center' style={{ height: '30px' }}>
        <input type={isShow ? 'text' : 'password'} className='custom-input' name='password' placeholder='◯◯◯◯◯◯◯◯' onChange={handleChange} />
        <i className={`eye-position cursor-pointer p-2 color-gray-accent ${isShow ? 'fa fa-eye-slash' : 'fa fa-eye'} ${!password ? 'disabled' : ''}`} onClick={handlePasswordShow}></i>
      </div>
    </div>
  )
}

export default InputPassword
