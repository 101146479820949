import { METHOD_TEXT } from '../constant/constant'
import { convertDetailDateTime } from './convert'
import { encrypt } from './crypto'
import { LocalStorageManager } from './localStorageManager'

export const handleCopy = (detail) => {
  const storeName = LocalStorageManager.getObjectItem('store').storeName
  const customerInfo = [
    `顧客名 : ${detail.customerName}`,
    `電話番号 : ${detail.customerNumber}`,
    `予約人数 : ${detail.customers}`,
    `金額 : ${detail.price ? `¥${detail.price}` : ''}`,
    `メッセージ : ${detail.product}`,
    `来店予定日 : ${convertDetailDateTime(detail.dateTime)}`,
    `決済方法 : ${detail.paymentMethod ? METHOD_TEXT[detail.paymentMethod - 1] : ''}`,
  ].join('\n')

  const headerText =
    `${storeName}です。\n` + (detail.paymentMethod === 3 ? 'ご予約ありがとうございます。\n' : 'ご予約を完了させてください。\n') + `${process.env.REACT_APP_BOOK_URL}${encrypt(detail._id)}\n\n`

  const data = headerText + customerInfo

  navigator.clipboard.writeText(data)
  alert(data)
}
