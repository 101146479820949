import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";

const InputSearch = ({ value, setValue, handleSearch }) => {
  return (
    <div className="mb-3">
      <Paper
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          border: "1px solid #BCBBB9",
          borderRadius: "12px",
          boxShadow: "none",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, fontWeight: "bold", p: "2px 4px" }}
          placeholder="名前で検索"
          value={value ?? ""}
          inputProps={{ "aria-label": "search google maps" }}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button
          sx={{
            p: "10px",
            borderRadius: "0 10px 10px 0",
          }}
          variant="contained"
          color="primary"
          aria-label="search"
          onClick={handleSearch}
        >
          <div className=" text-medium text-white mx-2">
            <i className="fa fa-search" />
            <span className="ms-2">検索</span>
          </div>
        </Button>
      </Paper>
    </div>
  );
};

export default InputSearch;
