import { useNavigate } from 'react-router-dom'

const StoreHeader = ({ title, page, value }) => {
  const navigate = useNavigate()
  return (
    <div className='store-header'>
      <div className={value ? 'text-white text20 top-store mb-2' : 'text-white text20 text-center mb-2'}>
        {value && <i className='ms-1 fa fa-arrow-left cursor-pointer' onClick={() => navigate(value)}></i>}
        <div>{title}</div>
      </div>
      <div className='d-flex justify-content-center'>
        <div className='step-bar'>
          <div className='step-item'>
            <div className='text-circle mb-2'>{page === 1 ? 1 : <i className='fa fa-check'></i>}</div>
            <div className='color-white text12 text-nowrap'>店舗情報</div>
          </div>
          <div>
            <div className='step-line'></div>
          </div>
          <div className='step-item z-5'>
            <div className='text-circle mb-2'>{[1, 2].includes(page) ? 2 : <i className='fa fa-check'></i>}</div>
            <div className='color-white text12 text-nowrap'>登録者情報</div>
          </div>
          <div>
            <div className='step-line'></div>
          </div>
          <div className='step-item'>
            <div className='text-circle mb-2'>{[1, 2, 3].includes(page) ? 3 : <i className='fa fa-check'></i>}</div>
            <div className='color-white text12 text-nowrap'>プラン選択</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreHeader
