import React, { useEffect, useState } from 'react'
import TopHeader from '../../component/TopHeader'
import axios from 'axios'
import { LocalStorageManager } from '../../util/localStorageManager'
import { getImage, getHeight } from '../../util/brand'
import CustomItem from '../../component/CustomItem'

const CardCheck = () => {
  const [brands, setBrands] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const store = LocalStorageManager.getObjectItem(LocalStorageManager.store)
    axios
      .post('/api/retrieveTenant', { tenantId: store.tenantId })
      .then((res) => {
        setBrands(res.data.brands)
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
  }, [])

  const handleAdd = () => {
    if (isSubmitting) return
    setIsSubmitting(true)
    const store = LocalStorageManager.getObjectItem(LocalStorageManager.store)
    axios
      .post('/api/getApplicationUrls', { tenantId: store.tenantId })
      .then((res) => {
        window.location = res.data.url + process.env.REACT_APP_RETURN_URL
      })
      .catch((error) => {
        alert(error.response?.data?.message ?? 'エラー')
        console.log(error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <div className='default-container bg-default m-auto'>
      <TopHeader pageTitle={'決済方法の設定'} value={-1} />
      <div className='grid-default-resize pb-5'>
        <CustomItem text={'カードブランドを追加する'} icon={'fa-regular fa-credit-card'} handleClick={() => handleAdd()} />
        {brands.length > 0 && (
          <div className='input-box mt-4'>
            <div className='color-gray-font text16 mb-2'>申請状況</div>
            {brands.map((brand, index) => {
              return (
                <div key={index}>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <img src={getImage(brand.brand)} className='me-3' alt='brand' style={{ height: getHeight(brand.brand) }} />
                      <span className={brand.status === 'passed' ? 'color-gray-font' : brand.status === 'in_review' ? 'color-gray-accent text-medium' : 'color-gray text-medium'}>{brand.brand}</span>
                    </div>
                    {brand.status === 'passed' ? (
                      <i className='fa fa-circle-check check-indicator'></i>
                    ) : brand.status === 'in_review' ? (
                      <span className='color-gray-accent text-medium'>申請中</span>
                    ) : (
                      <span className='color-gray text-medium'>棄却</span>
                    )}
                  </div>
                  {index !== brands.length - 1 && <hr className='color-gray-main'></hr>}
                </div>
              )
            })}
          </div>
        )}
        <div className='color-gray-sub input-box-gray w-100 mt-4'>
          <div>審査にかかる日数について</div>
          <div className='mt-3 text12'>Visa、Mastercardの場合</div>
          <div className='text-medium text12'>最短 2 営業日でPayJPよりご連絡があります。</div>
          <div className='mt-3 text12'>JCB、American Express、Discoverの場合</div>
          <div className='text-medium text12'>審査結果までに 15 営業日ほどでPayJPよりご連絡があります。</div>
        </div>
      </div>
    </div>
  )
}

export default CardCheck
