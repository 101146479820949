import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import conectic from '../assets/conectic-sm.svg'
import sallCallMe from '../assets/salon-call-me.svg'
import bgCall from '../assets/call.svg'
import bgMoney from '../assets/money.svg'
import bgContract from '../assets/contract.svg'
import CustomButton from '../component/CustomButton'
import IntroduceContent from '../component/IntroducContent'

const Introduce = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  return (
    <div className='start-container'>
      {page === 1 && (
        <div className='grid-2-row bg-store'>
          <div className='m-auto'>
            <div className='text-center'>
              <img src={conectic} className='mb-1' alt={'conectic'} />
              <br />
              <img src={sallCallMe} alt={'salon call me'} />
            </div>
            <div className='text-center text24 mt-4' style={{ color: '#7E6E67' }}>
              へようこそ！
            </div>
          </div>
          <div className='px-4'>
            <CustomButton text='次へ' handleOnClick={() => setPage(2)} />
          </div>
        </div>
      )}
      {page === 2 && <IntroduceContent title={'SMSでお客様に予約内容を送信'} image={bgCall} width={'70%'} order={page} text={'次へ'} handleOnClick={() => setPage(3)} returnFunc={() => setPage(1)} />}
      {page === 3 && <IntroduceContent title={'お客様が料金を前払い'} image={bgMoney} width={'70%'} order={page} text={'次へ'} handleOnClick={() => setPage(4)} returnFunc={() => setPage(2)} />}
      {page === 4 && (
        <IntroduceContent
          title={'予約ミス・急なキャンセルが\n発生しない'}
          image={bgContract}
          width={'70%'}
          order={page}
          text={'はじめる'}
          handleOnClick={() => navigate('/store-register-first')}
          returnFunc={() => setPage(3)}
        />
      )}
    </div>
  )
}

export default Introduce
