import TextareaAutosize from 'react-textarea-autosize'

const InputTextarea = ({ label, name, placeholder, value, handleChange, required }) => {
  return (
    <div className='input-box  d-flex flex-column mt-3'>
      <div className={`color-gray-accent ms-1 mb-1 ${value ? '' : 'text-medium'}`}>
        {label}
        {required && <span className='color-red'>*</span>}
      </div>
      <div className='d-flex justify-content-between align-items-top'>
        <TextareaAutosize className='custom-input textarea' name={name} placeholder={placeholder} value={value} onChange={handleChange} minRows={2} />
        {value && <i className='fa fa-circle-check check-indicator ms-2'></i>}
      </div>
    </div>
  )
}

export default InputTextarea
